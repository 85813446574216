


















































































































































































import Vue from 'vue';
import OwnerOrderService from '@/services/owner/OwnerOrderService';
import OrderStatus from '@/components/orders/OrderStatus.vue';
import ToastMessage from '@/models/ToastMessage';
import DataTable from '@/components/shared/DataTable.vue';
import Jumbotron from '@/components/shared/Jumbotron.vue';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Rating from '@/components/playrooms/Rating.vue';
import { de } from 'date-fns/locale';
import format from 'date-fns/format';
import SupportModal from '@/components/shared/SupportModal.vue';
import { MetaInfo } from 'vue-meta';

class OwnerOrderIndexDto {
  id!: string;
  playroomId!: string;
  playroomName!: string;
  bookingId!: string;
  status!: string;
  bookingType!: string;
  start!: Date;
  end!: Date;
  customerDisplayName!: string;
  createdAt!: string;
  amount!: number;
  rating!: number | null;
  canDownloadInvoice!: boolean;
}

class PlayroomDto {
  value!: string;
  label!: string;
}

class NewOrder {
  date = '';
  playroomId!: string;
}

const validations = {
  newOrder: {
    date: {
      required,
    },
    playroomId: {
      required,
    },
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  validations: validations,
  components: {
    OrderStatus,
    DataTable,
    Jumbotron,
    Rating,
    SupportModal,
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostOrderIndex.meta.title').toString(),
    };
  },
  data() {
    return {
      orders: [] as OwnerOrderIndexDto[],
      playrooms: [] as PlayroomDto[],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'createdAt', asc: false },
      currentPage: 1,
      loading: false,
      newOrder: new NewOrder(),
      message: '',
      showSupportModal: false,
      showOnlyFutureOrders: true,
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
    showOnlyFutureOrders: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostOrderIndex.breadcrumb.last' },
      ]);
    },

    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },

    formatDate(date: Date): string {
      let dt = new Date(date);
      return format(dt, 'P | p', { locale: de });
    },

    openSupportModal() {
      this.showSupportModal = true;
    },

    closeSupportModal() {
      this.showSupportModal = false;
    },

    downloadInvoice(order: OwnerOrderIndexDto) {
      this.$store.commit(
        'addToastMessage',
        new ToastMessage(this.$t('components.hostOrderDetailButtons.downloadMsg').toString(), 'bg-success')
      );
      OwnerOrderService.getInvoice(order.id).then((res) => {
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = 'Rechnungsvorlage.pdf';
        link.href = 'data:application/octet-stream;base64,' + res.value;
        link.click();
      });
    },

    loadData() {
      this.loading = true;
      OwnerOrderService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue,
        this.showOnlyFutureOrders.toString()
      ).then((res) => {
        this.loading = false;
        this.playrooms = res.value.playrooms;
        this.orders = res.value.orders.items;
        this.pages = res.value.orders.totalPages;
        this.setBreadCrumb();
      });
    },
  },
});
