import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/Order/';

class OwnerOrderService {
  createNew(order: {}) {
    return ApiService.post(`${API_URL}Create`, order);
  }

  editNote(orderId: string, orderNotes: string, guestNotes: string) {
    return ApiService.post(`${API_URL}EditNotes`, {
      orderNotes,
      guestNotes,
      orderId: orderId,
    });
  }

  edit(order: any) {
    order.amount = String(order.amount);
    return ApiService.post(`${API_URL}Edit?orderId=${order.id}`, order);
  }

  editDetail(order: any) {
    return ApiService.post(`${API_URL}EditDetail`, order);
  }

  getEdit(orderId: string) {
    return ApiService.get(`${API_URL}Edit?orderId=${orderId}`);
  }

  getInvoice(orderId: string) {
    return ApiService.get(`${API_URL}Invoice?orderId=${orderId}`);
  }

  getDetail(orderId: string) {
    return ApiService.get(`${API_URL}Details?orderId=${orderId}`);
  }

  cancelOrder(orderId: string, cancellationFeeInPercentage: number | string, hostMessage: string) {
    cancellationFeeInPercentage = cancellationFeeInPercentage.toString();
    return ApiService.post(`${API_URL}CancelOrder`, { orderId, cancellationFeeInPercentage, hostMessage });
  }

  acceptOrder(id: any, message: string) {
    return ApiService.post(`${API_URL}AcceptOrder?orderId=${id}`, { message });
  }

  rejectOrder(id: any, message: string) {
    return ApiService.post(`${API_URL}rejectOrder?orderId=${id}`, { message });
  }

  getIndex(
    currentPage: number,
    sortColumn: string,
    sortAsc: boolean,
    tableFilter: string,
    showOnlyFutureOrders: string
  ) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}&showOnlyFutureOrders=${showOnlyFutureOrders}`
    );
  }
}

export default new OwnerOrderService();
